import styles from "./Banner.module.scss";
import { Link } from "react-router-dom";
import { useContext } from "react"; // 引入 useContext
import { UserContext } from "../../../context/userContext"; // 引入 UserContext
import axios from 'axios';
import { BACKEND_URL } from "../../../constants";

const Banner = () => {

    // 使用 useContext 获取上下文数据
    const { user } = useContext(UserContext);

    // 打印 UserContext 内容
    console.log("UserContext: ", user);

    const cart = JSON.parse(sessionStorage.getItem('cart')) || [];

    const bear = "/assets/images/bear-icon-white.png";
    const coin = "/assets/images/coin.png";

    // Calculate the subtotal
    const subtotal = cart.reduce((total, item) => {
        return total + item.price * item.quantity;
    }, 0);  // Initial value is 0

    const handlePayNow = async () => {
        try {
            const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
            if (!user || !user.userId) {
                alert("User is not logged in or user ID is missing.");
                return;
            }
            const data = {
                user_id: user.userId, // 确保从 useContext 获取到 user_id
                coin_type_id: 1,  // 假设的硬币类型 ID
                amount: cart.reduce((total, item) => total + item.price * item.quantity, 0), // 总金额
                transaction_type: "order", // 交易类型
                bot_id: 241 // bot ID
            };
    
            const response = await axios.post(`${BACKEND_URL}crmCACoins/createTransaction`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const { data: responseData } = response;
    
            if (responseData && responseData.success) {
                sessionStorage.removeItem('cart');
                window.location.href = responseData.url;
            } else {
                alert("Payment failed!");
            }
        } catch (error) {
            console.error("Payment error: ", error);
            alert("Payment failed!");
        }
    };
    

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img
                src={bear}
                alt="PokinPokin Home Page"
                />
                <h1>ORDER<br/>SUMMARY</h1>
            </div>
            <div className={styles.subtitle}>
                <h2>#123456789</h2>
                <p>August 9, 2024 at 12:24pm</p>
            </div>
            <div className={styles.summary}>
                <div className={styles.itemsSummary}>
                    {cart.length === 0 ? (
                        <p className={styles.noItems}>No items in cart</p>
                    ) : (
                        cart.map(item => (
                            <div className={styles.description} key={item.id}>
                                <div className={styles.itemName}>
                                    <img src={coin} alt="coin"/>
                                    <h1>{item.size}</h1>
                                    <h1>coins</h1>
                                </div>
                                <div className={styles.itemQuantity}>
                                    <p>x</p>
                                    <p>{item.quantity}</p>
                                </div>
                                <div className={styles.itemPrice}>
                                    <p>${item.quantity * item.price}</p>
                                </div>
                            </div>
                        )
                    ))}
                    <div className={styles.line}/>
                    <div className={styles.subtotal}>
                        <h4>Subtotal</h4>
                        <p>${subtotal}</p>
                    </div>
                    <div className={styles.tax}>
                        <h4>Tax</h4>
                        <p>$0</p>
                    </div>
                    <div className={styles.line}/>
                    <div className={styles.total}>
                        <h4>Total</h4>
                        <p>${subtotal}</p>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <Link to="/cart" className={styles.back}>
                    Back
                </Link>
                <button className={styles.pay} onClick={handlePayNow}>
                    Pay Now
                </button>
            </div>
        </div>
        </>
    );
};

export default Banner;
