import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { UserContext } from "../../context/userContext";
import Cookies from "js-cookie"; // 引入 js-cookie

const LoginSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const userId = queryParams.get("userId"); // 获取 userId
    const userType = queryParams.get("userType"); // 获取 userType
  
    if (code) {
      axios
        .post(`${BACKEND_URL}crmUsers/exchangeToken`, { code })
        .then((response) => {
          const token = response.data.token;
  
          // 将 token、userId 和 userType 存储在 cookie 中
          Cookies.set("token", token, { expires: 15 }); // 15天过期时间
          Cookies.set("user_id", userId, { expires: 15 });
          Cookies.set("user_type", userType, { expires: 15 });
  
          // 更新 UserContext
          setUser({ userId, userType });
  
          // 跳转到主页面
          navigate("/special_events");
        })
        .catch((error) => {
          console.error("交换令牌时出错：", error);
          // 处理错误，例如显示提示信息或重定向到登录页面
          navigate("/login");
        });
    } else {
      // 如果没有 code 参数，重定向到登录页面
      navigate("/login");
    }
  }, [location, navigate, setUser]);
  

  return <div>正在处理登录...</div>;
};

export default LoginSuccess;
