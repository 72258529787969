import styles from "./Banner.module.scss";

const Banner = () => {

    const bear = "/assets/images/bear-icon.png";
    const cake = "/assets/images/birthday_cake.png";

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Special Events Page"/>
                <h1>SPECIAL<br/>EVENTS</h1>
            </div>
            <div className={styles.content}>
                <img src={cake} alt="Birthday Cake"/>
                <div className={styles.words}>
                    <h2>Birthday Party</h2>
                    <p>Want to make a reservation? Choose the store you'd like to<br/>book and click 'Call Now' to give us a call and schedule your<br/>birthday party!</p>
                    <button className={styles.location}>
                        Upper Canada Mall Store
                    </button>
                    <button className={styles.location}>
                        Scarborough Town Centre Store
                    </button>
                    <button className={styles.location}>
                        Metropolis at Metrotown Store
                    </button>
                    <button className={styles.contact}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
        </>
    );

};

export default Banner;