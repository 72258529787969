import styles from "./About.module.scss";

const About = () => {
    const bear = "./assets/images/bear-icon.png";
    const machine = "./assets/images/home-about.png";

    return (
        <>
        <div className={styles.aboutWrapper}>
            <div className={styles.col1}>
                <div className={styles.title}>
                    <img
                    src={bear}
                    alt="PokinPokin"
                    />
                    <h1>ABOUT<br/>POKINPOKIN</h1>
                </div>
                <div className={styles.words}>
                PokinPokin is a leading chain brand in the claw machine industry, recognized for its pioneering concepts and swift growth. As the exclusive authorized offline partner collaborating with numerous designer IP images, we are dedicated to providing the latest, most comprehensive, and highly sought-after IP derivatives. Our commitment is to transform the traditional shopping experience into a lively and interactive adventure. By continually introducing fresh and popular products, we aim to captivate and delight our customers, ensuring an unforgettable and engaging experience each time they visit our stores. At PokinPokin, we strive to merge entertainment with retail, creating a unique environment where fun and shopping go hand in.
                </div>
                <button className={styles.button}>Learn More</button>
            </div>
            <div className={styles.col2}>
                <img
                src={machine}
                alt="About Page"
                />
            </div>
        </div>
        </>
    );
};

export default About;