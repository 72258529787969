import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie"; // 引入 js-cookie

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ userId: null, userType: null });

  // 尝试从 cookie 中获取用户信息
  useEffect(() => {
    const userId = Cookies.get("user_id");
    const userType = Cookies.get("user_type");

    if (userId && userType) {
      setUser({ userId, userType });
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
