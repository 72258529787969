import styles from "./Brand.module.scss";

const Brand = () => {

    const bear = "/assets/images/bear-icon.png";
    const timeline = "/assets/images/timeline.png";
    const left = "/assets/images/left-arrow.png";
    const right = "/assets/images/right-arrow.png";

    let imagePosition = 0;

    const shift_left = () => {
        const image = document.getElementById("timeline");
        if (imagePosition === 0) {
            imagePosition = 20;
            image.style.left = `${imagePosition}%`;
        } else if (imagePosition === -20) {
            imagePosition = 0;
            image.style.left = `${imagePosition}%`;
        }
    };
    
    const shift_right = () => {
        const image = document.getElementById("timeline");
        if (imagePosition === 0) {
            imagePosition = -20;
            image.style.left = `${imagePosition}%`;
        } else if (imagePosition === 20) {
            imagePosition = 0;
            image.style.left = `${imagePosition}%`;
        }
    };

    return (
        <>
        <div className={styles.brandWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Home Page"/>
                <h1>BRAND<br/>DEVELOPMENT</h1>
            </div>
            <div className={styles.timelineWrapper}>
                <img 
                id="timeline"
                src={timeline}
                alt="Brand Timeline"
                className={styles.timeline}/>
            </div>
            <div className={styles.mover}>
                <h1 className={styles.left} onClick={shift_left}>
                {"<"}
                </h1>
                <h1 className={styles.right} onClick={shift_right}>
                {">"}
                </h1>
            </div>
        </div>
        </>
    );

};

export default Brand;