import styles from "./Banner.module.scss"
import { Link } from "react-router-dom";
import { useIsMobile } from "../../../hooks/useIsMobile.js";

const Banner = () => {
    const background = "./assets/images/home-background.png";
    const logoWhite = "./assets/images/logo-white.png";
    const login = "./assets/images/login.png";
    const shop = "./assets/images/shopping.png";

    const isMobile = useIsMobile();

    return (
        <>
            <div className={styles.bannerWrapper}>
                <img
                src={background}
                alt="PokinPokin Home Page"
                className={styles.background}
                />
                <img
                src={logoWhite}
                alt="PokinPokin"
                className={styles.logo}
                />
                <div className={styles.orderButton}>
                    <Link to="/order_online">
                    <button>
                        Order Online
                    </button>
                    </Link>
                    <Link to="/special_events">
                    <button>
                        Birthday Party
                    </button>
                    </Link>
                </div>
                {isMobile ? (
                    <>
                    <Link to="/login">
                    <div className={styles.mobileLoginButton}>
                        <img
                        src={login}
                        alt="Login"
                        />
                    </div>
                    </Link>
                    <Link to="/cart">
                    <div className={styles.mobileCartButton}>
                        <img
                        src={shop}
                        alt="Shopping Cart"
                        />
                    </div>
                    </Link>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default Banner;