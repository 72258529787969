import styles from "./Banner.module.scss";
import { Link } from "react-router-dom";

const Banner = () => {

    const bear = "/assets/images/bear-icon-white.png";
    const coin = "/assets/images/coin.png";

    const cart = JSON.parse(sessionStorage.getItem('cart')) || [];

    function addItemToCart(itemId, bundleSize, itemPrice, quantity) {
    
        // Check if the item already exists in the cart
        const existingItem = cart.find(item => item.id === itemId);
        if (existingItem) {
            // Update the quantity if it exists
            existingItem.quantity = quantity;
        } else {
            // Add a new item if it doesn't exist
            cart.push({ id: itemId, size: bundleSize, price: itemPrice, quantity: quantity });
        }
    
        // Store the updated cart in sessionStorage
        sessionStorage.setItem('cart', JSON.stringify(cart));
    }

    const removeItem = (itemId) => {
        const newCart = cart.filter(item => item.id !== itemId);
        sessionStorage.setItem('cart', JSON.stringify(newCart));
    }

    const tenItem = cart.find(item => item.id === "Small");
    const twentyItem = cart.find(item => item.id === "Normal");
    const fiftyItem = cart.find(item => item.id === "Large");
    const hundredItem = cart.find(item => item.id ==="Extra Large");

    const addToCart = () => {
        if (ten !== 0) {
            addItemToCart("Small", "11", 10, ten); //adding bundle worth $10
        } else if(tenItem) {
            removeItem("Small");
        }
        if (twenty !== 0) {
            addItemToCart("Normal", "23", 20, twenty); //adding bundle worth $20
        } else if(twentyItem) {
            removeItem("Normal");
        }
        if (fifty !== 0) {
            addItemToCart("Large", "60", 50, fifty); //adding bundle worth $50
        } else if(fiftyItem) {
            removeItem("Large");
        }
        if (hundred !== 0) {
            addItemToCart("Extra Large", "130", 100, hundred); //adding bundle worth $100
        } else if(hundredItem) {
            removeItem("Extra Large");
        }
    }

    let ten = 0;
    let twenty = 0;
    let fifty = 0;
    let hundred = 0;

    if(tenItem){
        ten = tenItem.quantity;
    }
    if(twentyItem){
        twenty = twentyItem.quantity;
    }
    if(fiftyItem){
        fifty = fiftyItem.quantity;
    }
    if(hundredItem){
        hundred = hundredItem.quantity;
    }

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img
                src={bear}
                alt="PokinPokin Home Page"
                />
                <h1>ORDER<br/>ONLINE</h1>
            </div>
            <div className={styles.subtitle}>
                <h2>COIN PURCHASE PACKAGE</h2>
                <p>All price are <span>HST INCLUDED</span></p>
            </div>
            <div className={styles.packages}>
                <div className={styles.option}>
                    <button className={styles.button}>
                        <div className={styles.buttonText}>
                            <div className={styles.buttonLeft}>
                                <h1>11</h1>
                                <img src={coin} alt="coin"/>
                            </div>
                            <div className={styles.buttonRight}>
                                <p>$10</p>
                            </div>
                        </div>
                    </button>
                    <div className={styles.increment}>
                        <button onClick={() => {
                            if (ten > 0) { 
                                ten -= 1; 
                                document.getElementById("ten").textContent = ten;
                            }}}>-</button>
                        <p id="ten">{ten}</p>
                        <button onClick={() => {
                            ten += 1; 
                            document.getElementById("ten").textContent = ten;
                            }}>+</button>
                    </div>
                </div>
                <div className={styles.option}>
                    <button className={styles.button}>
                        <div className={styles.buttonText}>
                            <div className={styles.buttonLeft}>
                                <h1>23</h1>
                                <img src={coin} alt="coin"/>
                            </div>
                            <div className={styles.buttonRight}>
                                <p>$20</p>
                            </div>
                        </div>
                    </button>
                    <div className={styles.increment}>
                        <button onClick={() => {
                            if (twenty > 0) { 
                                twenty -= 1; 
                                document.getElementById("twenty").textContent = twenty;
                            }}}>-</button>
                        <p id="twenty">{twenty}</p>
                        <button onClick={() => {
                            twenty += 1; 
                            document.getElementById("twenty").textContent = twenty;
                            }}>+</button>
                    </div>
                </div>
                <div className={styles.option}>
                    <button className={styles.button}>
                        <div className={styles.buttonText}>
                            <div className={styles.buttonLeft}>
                                <h1>60</h1>
                                <img src={coin} alt="coin"/>
                            </div>
                            <div className={styles.buttonRight}>
                                <p>$50</p>
                            </div>
                        </div>
                    </button>
                    <div className={styles.increment}>
                        <button onClick={() => {
                            if (fifty > 0) { 
                                fifty -= 1; 
                                document.getElementById("fifty").textContent = fifty;
                            }}}>-</button>
                        <p id="fifty">{fifty}</p>
                        <button onClick={() => {
                            fifty += 1; 
                            document.getElementById("fifty").textContent = fifty;
                            }}>+</button>
                    </div>
                </div>
                <div className={styles.option}>
                    <button className={styles.button}>
                        <div className={styles.buttonText}>
                            <div className={styles.buttonLeft}>
                                <h1>130</h1>
                                <img src={coin} alt="coin"/>
                            </div>
                            <div className={styles.buttonRight}>
                                <p>$100</p>
                            </div>
                        </div>
                    </button>
                    <div className={styles.increment}>
                        <button onClick={() => {
                            if (hundred > 0) { 
                                hundred -= 1; 
                                document.getElementById("hundred").textContent = hundred;
                            }}}>-</button>
                        <p id="hundred">{hundred}</p>
                        <button onClick={() => {
                            hundred += 1; 
                            document.getElementById("hundred").textContent = hundred;
                            }}>+</button>
                    </div>
                </div>
            </div>
            <div className={styles.note}>
                <p>The machine only accepts banknotes, coins are not accepted.</p>
                <p>(We can help you to convert coins into banknotes)</p>
            </div>
            <Link to="/cart" className={styles.addCart} onClick={addToCart}>
                Add To Cart
            </Link>
        </div>
        </>
    );

};

export default Banner;