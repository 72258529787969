import styles from "./Banner.module.scss";

const Banner = () => {

    const bear = "/assets/images/bear-icon.png"
    const image = "/assets/images/location.png";
    const left = "/assets/images/dark-left.png";
    const right = "/assets/images/dark-right.png";

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Special Events Page"/>
                <h1>OUR<br/>GALLERY</h1>
            </div>
            <div className={styles.buttons}>
                <button>
                    <p>Mini Plush</p>
                </button>
                <button>
                    <p>Standard Plush</p>
                </button>
                <button>
                    <p>Giant Plush</p>
                </button>
            </div>
            <div className={styles.gallery}>
                <img src={image} alt="PokinPokin Gallery"/>
                <img src={image} alt="PokinPokin Gallery"/>
                <img src={image} alt="PokinPokin Gallery"/>
                <img src={image} alt="PokinPokin Gallery"/>
                <img src={image} alt="PokinPokin Gallery"/>
                <img src={image} alt="PokinPokin Gallery"/>
            </div>
            <div className={styles.mover}>
                <img src={left} alt="Left Arrow" className={styles.left}/>
                <img src={right} alt="Right Arrow" className={styles.right}/>
            </div>
        </div>
        </>
    );

};

export default Banner;